import { template as template_54120ce364d74edcb7c66e6c60f8bfd6 } from "@ember/template-compiler";
const FKLabel = template_54120ce364d74edcb7c66e6c60f8bfd6(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
