import { template as template_dc3f19bb06804fed8db580cd2398748d } from "@ember/template-compiler";
const SidebarSectionMessage = template_dc3f19bb06804fed8db580cd2398748d(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
