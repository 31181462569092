import { template as template_cb6a107e482c4ac5ba2f603c488db76e } from "@ember/template-compiler";
const FKText = template_cb6a107e482c4ac5ba2f603c488db76e(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
