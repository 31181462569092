import { template as template_53351089e7434e59845253f89cae7520 } from "@ember/template-compiler";
const FKControlMenuContainer = template_53351089e7434e59845253f89cae7520(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
